import React from 'react'
import {useState} from 'react'
import { Container, Row, Col, Modal} from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import MediaEmbed from '../components/media-embed'
import * as styles from './media-box.module.scss'
import play from '../images/play.png'


export default (props) => {
  //const page = usePage();


  let VideoContent = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if(props.videoImage !== null && typeof props.videoImage !== 'undefined'){
      let videoBackgroundFluidImageStack = [];


      if(props.videoUrl !== null && typeof props.videoUrl !== 'undefined') {
        if(props.videoImage.localFile !== undefined){
          videoBackgroundFluidImageStack = [
            props.videoImage.localFile.childImageSharp.fluid,
            `linear-gradient(0deg,rgba(0,0,0,0),rgba(0,0,0,.3))`,
          ].reverse()
        }
        return(
          <BackgroundImage
            className="d-flex w-100"
            Tag="div"
            fluid={videoBackgroundFluidImageStack}
            backgroundColor={`#040e18`}
          >
            <div className="d-flex align-items-center justify-content-center w-100">
              <button className="d-block h-100 w-100" style={{background: "none", border: "0", minHeight: '200px'}} onClick={handleShow}><img src={play} alt="Play" /></button>
            </div>
            <Modal show={show} onHide={handleClose}
              centered={true} size="xl" >
            <Modal.Header closeButton></Modal.Header>
              <Modal.Body >
                <MediaEmbed videoUrl={props.videoUrl} autoplay={true} />
              </Modal.Body>
            </Modal>
          </BackgroundImage>
        )
      } else {
        if(props.videoImage.localFile !== undefined){
          videoBackgroundFluidImageStack = [
            props.videoImage.localFile.childImageSharp.fluid,
          ].reverse()
        }
        return(
          <BackgroundImage
            className="d-flex w-100"
            Tag="div"
            fluid={videoBackgroundFluidImageStack}
            backgroundColor={`#040e18`}
          ></BackgroundImage>
        )
      }
    } else {
      return (
        <div>No video thumbnail set.</div>
      )
    }

  }
  let TextContent = () => {
    return(
      <div>
        <h4 className="text-primary">{props.subtitle}</h4>
        <h2 className="mb-0">{props.title}</h2>
        <hr />
        { props.children }
      </div>
    )
  }
  let TextContentMobile = () => {
    return(
      <div>
        <h4 className="text-center text-lg-left text-primary">{props.subtitle}</h4>
        <h2 className="text-center text-lg-left mb-0">{props.title}</h2>
        <hr />
        <div className="d-lg-none">
          <VideoContent />
        </div>
        { props.children }
      </div>
    )
  }

  if (props.orientation === 'video') {
    return(
      <Container className={props.bgColor}>
        <Row className="px-4 px-lg-0">
          <Col lg={12} className="bg-dark p-0 d-flex" style={{minHeight: "300px"}}>
            <VideoContent />
          </Col>
        </Row>
      </Container>
    )
  } else if (props.orientation === 'left' && !props.mobileTitle) {
    return(
      <Container className={props.bgColor}>
        <Row className="px-4 px-lg-0">
          <Col lg={6} className="bg-dark p-0 d-flex" style={{minHeight: "300px"}}>
            <VideoContent />
          </Col>
          <Col>
            <div className={styles.leftTextContent}>
              <TextContent />
            </div>
          </Col>
        </Row>
      </Container>
    )
  } else if (props.orientation === 'left' && props.mobileTitle){
    return(
      <Container className={props.bgColor}>
        <Row>
          <Col lg={6} className="d-none bg-dark p-0 d-lg-flex" style={{minHeight: "300px"}}>
            <VideoContent />
          </Col>
          <Col>
            <div className={styles.leftTextContent}>
              <TextContentMobile />
            </div>
          </Col>
        </Row>
      </Container>
    )
  } else if (props.orientation === 'right-50') {
    return(
      <Container className={"text-white"}>
        <Row>
          <Col lg={6} className="p-0">
            <div className={styles.textContent}>
              <TextContent />
            </div>
          </Col>
          <Col lg={6} className="bg-dark d-none d-lg-flex p-0" style={{minHeight: "300px"}}>
            <VideoContent />
          </Col>
        </Row>
      </Container>
    )
  } else {
    return(
      <Container className={"text-white"}>
        <Row className="mx-2 mx-lg-0">
          <Col lg={4} className="p-0">
            <div className={styles.textContent}>
              <TextContent />
            </div>
          </Col>
          <Col lg={8} className="bg-dark d-none d-lg-flex p-0" style={{minHeight: "300px"}}>
            <VideoContent />
          </Col>
        </Row>
      </Container>
    )
  }

}
